import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import TextField from 'components/fields/TextField';
import { closeAddTrackerNoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import { useCallback, useState } from 'react';
import { LuImagePlus, LuX } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';

export default function AddTrackerNoteDialog() {
  const dispatch = useDispatch<any>();
  const dialog = useSelector(
    (state: RootState) => state.dialogs.addTrackerNoteDialog,
  );
  const [note, setNote] = useState<string>('');

  const onClose = useCallback(() => {
    dispatch(closeAddTrackerNoteDialog());
  }, [dispatch]);

  const handleCreateTrackerNote = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      isOpen={!!dialog}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent minW="350px" p="18px 16px" gap="18px" borderRadius="18px">
        <HStack justifyContent="flex-end">
          <Icon
            as={LuX}
            color="#00000066"
            w="20px"
            h="20px"
            cursor="pointer"
            onClick={onClose}
          />
        </HStack>
        <TextField
          name="note"
          w="100%"
          maxLength={100}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNote(e.target.value)
          }
          minH="140px"
          resize="none"
          value={note}
          autoFocus
          iconRight={
            <Flex alignItems="center" gap="16px" w="100%" m="0 12px">
              <IconButton
                aria-label=""
                variant="iconButton"
                icon={
                  <Icon as={LuImagePlus} w="24px" h="24px" color="#8E7A70" />
                }
              />
              <Text variant="textAreaLengthCounter">{note.length}/100</Text>
            </Flex>
          }
          iconProps={{ width: 'auto' }}
        />
        <HStack justifyContent="flex-end">
          <Button
            variant="h1cta"
            minW="134px"
            onClick={handleCreateTrackerNote}>
            Save
          </Button>
        </HStack>
      </ModalContent>
    </Modal>
  );
}
