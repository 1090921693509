import { Flex, HStack, Text } from '@chakra-ui/react';

export default function TextNotification() {
  return (
    <Flex
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="16px 8px"
      flexDirection="column">
      <HStack>
        <Text variant="cardSubTitle" color="#1B2037">
          Here comes some kind of notification text without icon or button!
        </Text>
      </HStack>
      <Text fontSize="md" color="gray.500" alignSelf="flex-end">
        1 hour ago
      </Text>
    </Flex>
  );
}
