import { Flex, HStack, Text, VStack } from '@chakra-ui/layout';
import {
  Button,
  Collapse,
  Icon,
  IconButton,
  Spinner,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { format } from 'date-fns';
import { enGB, he } from 'date-fns/locale';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import EventCard from './EventCard';
import HolidayCard from './HolidayCard';
import MeetingCard from './MeetingCard';
export default function EventsAndMeeting() {
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const { selectedDate, calendarEvents, isMeetingsLoading, isEventsLoading } =
    useSelector((state: RootState) => ({
      selectedDate: state.dashboard.selectedDate,
      calendarEvents: state.calendarEvent.calendarEvents,
      isMeetingsLoading: state.calendarEvent.loading,
      isEventsLoading: state.calendarEvent.loading,
    }));

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const today = useMemo(
    () =>
      format(selectedDate, 'dd MMMM yy', {
        locale: i18n.language.startsWith('he') ? he : enGB,
      }),
    [i18n.language, selectedDate],
  );

  // month-year-day format
  const keyQuery = useMemo(
    () => format(selectedDate, 'yyyy-MM-dd'),
    [selectedDate],
  );

  const events = useMemo(() => {
    return calendarEvents?.filter(
      (event) =>
        event.category === 'event' &&
        format(event.start, 'yyyy-MM-dd') === keyQuery,
    );
  }, [calendarEvents, keyQuery]);

  const meetings = useMemo(() => {
    return calendarEvents?.filter(
      (event) =>
        event.category === 'meeting' &&
        format(event.start, 'yyyy-MM-dd') === keyQuery,
    );
  }, [calendarEvents, keyQuery]);

  const holidays = useMemo(() => {
    return calendarEvents?.filter(
      (event) =>
        event.category === 'holiday' &&
        format(event.start, 'yyyy-MM-dd') === keyQuery,
    );
  }, [calendarEvents, keyQuery]);

  return (
    <Card
      flexDirection="column"
      w="100%"
      minW={310}
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      p="31px 26px 0"
      height="100%"
      overflow="hidden"
      gap={3}>
      <Text variant="cardTitle">{today}</Text>
      <HStack w="100%" justify="space-between">
        <HStack>
          <Text variant="eventCardTitle">{t('events_meeting.events')}</Text>
          <Switch
            onChange={() => onToggle()}
            dir={i18n.dir()}
            variant="brand"
            isChecked={isOpen}
          />
        </HStack>
        {events?.length > 0 && (
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate('/main/managment/events/create')}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        )}
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <Flex
          flexDirection="column"
          maxH="220px"
          w="100%"
          overflow="hidden"
          overflowY="auto"
          gap="10px">
          {isEventsLoading && (
            <Spinner alignSelf="center" justifySelf="center" />
          )}
          {events?.map((event, key: number) => (
            <EventCard calendarEvent={event} key={key} isDelete />
          ))}
          {events?.length === 0 && (
            <VStack p="50px 0 36px">
              <Text variant="taskCardSubTitle">
                {t('events_meeting.no_events')}
              </Text>
              <Button
                variant="h1cta"
                onClick={() => navigate('/main/managment/events/create')}>
                {t('events_meeting.create_event')}
              </Button>
            </VStack>
          )}
        </Flex>
      </Collapse>
      <HStack w="100%" justify="space-between">
        <Text variant="eventCardTitle">{t('events_meeting.schedule')}</Text>
        {meetings?.length > 0 && (
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            onClick={() => navigate('/main/meetings/create')}
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        )}
      </HStack>
      <Flex
        flexDirection="column"
        w="100%"
        overflow="hidden"
        overflowY="auto"
        gap="10px">
        {isMeetingsLoading && (
          <Spinner alignSelf="center" justifySelf="center" />
        )}
        {holidays?.map((holiday, key: number) => (
          <HolidayCard calendarHoliday={holiday} key={key} />
        ))}
        {meetings
          ?.sort((a, b) => (a.start.getTime() > b.start.getTime() ? 1 : -1))
          ?.map((calendarMeeting, key: number) => (
            <MeetingCard calendarMeeting={calendarMeeting} key={key} isDelete />
          ))}
        {meetings?.length === 0 && (
          <VStack pt="50px">
            <Text variant="taskCardSubTitle">
              {t('events_meeting.no_meetings')}
            </Text>
            <Button
              variant="h1cta"
              onClick={() => navigate('/main/meetings/create')}>
              {t('events_meeting.create_meeting')}
            </Button>
          </VStack>
        )}
      </Flex>
    </Card>
  );
}
