import {
  Checkbox,
  Flex,
  HStack,
  Switch,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface SettingItemProps {
  title: string;
}
export default function SettingItem(props: SettingItemProps) {
  const { title } = props;
  const { i18n, t } = useTranslation();
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: true,
  });
  return (
    <Flex flexDir="column" alignItems="start" gap="16px" minW="320px">
      <HStack gap="16px">
        <Text variant="settingItemTitle">
          {t(`notification_settings_item.title.${title}`)}
        </Text>
        <Switch
          isChecked={isOpen}
          dir={i18n.dir()}
          onChange={onToggle}
          variant="brand"
        />
      </HStack>
      <VStack alignItems="start" fontWeight="500" gap="16px">
        <Checkbox isDisabled={!isOpen} variant="roundBrand">
          {t('notification_settings_item.all')}
        </Checkbox>
        <Checkbox isDisabled={!isOpen} variant="roundBrand">
          {t('notification_settings_item.notify_by_email')}
        </Checkbox>
        <Checkbox isDisabled={!isOpen} variant="roundBrand">
          {t('notification_settings_item.notify_by_message')}
        </Checkbox>
        <Checkbox isDisabled={!isOpen} variant="roundBrand">
          {t('notification_settings_item.push_notification')}
        </Checkbox>
      </VStack>
    </Flex>
  );
}
