import { Box, Collapse, Flex, Icon, IconButton, Text } from '@chakra-ui/react';
import { setAddTrackerNoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TiPlus } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import TrackItemNote from './TrackItemNote';

export default function TrackItem(prors: {
  title: string;
  time: string;
  isLast?: boolean;
  isNoteVisible?: boolean;
}) {
  const { title, time, isLast = false, isNoteVisible } = prors;
  const dispatch = useDispatch<any>();
  const { i18n } = useTranslation();
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);

  const handleCreateTrackerNote = useCallback(() => {
    dispatch(setAddTrackerNoteDialog({}));
  }, [dispatch]);
  return (
    <Flex
      justifyContent="space-between"
      onMouseEnter={() => setIsBtnVisible(true)}
      onMouseLeave={() => setIsBtnVisible(false)}>
      <Flex gap="17px">
        <Flex flexDirection="column" gap="5px">
          <Box
            w="15px"
            minH="15px"
            border="2px solid #AA915D"
            borderRadius="50%"
          />
          {!isLast && (
            <Box
              w="calc(16px / 2)"
              h="100%"
              minH="50px"
              borderRight={i18n.dir() === 'ltr' ? '2px dashed #AA915D' : 'none'}
              borderLeft={i18n.dir() === 'rtl' ? '2px dashed #AA915D' : 'none'}
            />
          )}
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Flex flexDirection="column">
            <Text variant="cardSectionInfo" lineHeight="14px" ps="0px">
              {title}
            </Text>
            <Text variant="taskCardSubTitle">{time}</Text>
          </Flex>
          <Collapse in={isNoteVisible} animateOpacity>
            <TrackItemNote />
          </Collapse>
        </Flex>
      </Flex>

      <IconButton
        aria-label="Add"
        variant="actionIconBlue"
        icon={<Icon as={TiPlus} w="15px" h="15px" color="#EEEEEE" />}
        onClick={handleCreateTrackerNote}
        display="flex"
        maxW="24px"
        minW="24px"
        height="24px"
        justifySelf="flex-end"
        transition="all 0.4s linear"
        visibility={{
          base: 'visible',
          md: isBtnVisible ? 'visible' : 'hidden',
        }}
      />
    </Flex>
  );
}
