import { Flex, Icon, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineMailOpen } from 'react-icons/hi';
import { IoLocationOutline } from 'react-icons/io5';
import { IEvent } from 'services/@types';
import EventTabButton from './EventTabButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const eventTabButtons: {
  type: 'main' | 'client_file' | 'finance' | 'supplier_assignment';
  max: number;
  current: number;
  title: string;
}[] = [
  {
    title: 'event_tab_buttons.general',
    max: 10,
    current: 5,
    type: 'main',
  },
  {
    title: 'event_tab_buttons.finance',
    max: 3,
    current: 1,
    type: 'finance',
  },
  {
    title: 'event_tab_buttons.client_file',
    max: 5,
    current: 3,
    type: 'client_file',
  },
  {
    title: 'event_tab_buttons.supplier_assignment',
    max: 2,
    current: 1,
    type: 'supplier_assignment',
  },
];

export default function ListEventCard({ event }: { event: IEvent }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const eventDate = useMemo(() => {
    const date = moment(event.dateAndTime)
      .locale(i18n.language)
      .format('MMMM DD, YYYY hh:mm A');
    return date;
  }, [event.dateAndTime, i18n.language]);

  return (
    <Flex
      borderRadius="24px"
      w="100%"
      flex="1"
      p="16px"
      border="1px solid #EBEDF0"
      gap="16px"
      cursor="pointer"
      onClick={() => navigate(`/main/event/${event?.id}`)}
      bg="#fafbfc"
      transition="all 0.2s linear"
      _hover={{
        bg: '#ffffff',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.04)',
      }}>
      <Flex
        flexDirection="column"
        w={{ base: '275px', lg: '325px', '3xl': '425px' }}>
        <Flex alignItems="center" gap="16px">
          <Flex
            bg={`eventStatus.${event.status}`}
            borderRadius="16px"
            w="144px"
            justifyContent="center"
            alignItems="center"
            p="6px 0">
            <Text variant="eventGridCardStatus">
              {t(`event.status.${event.status}`)}
            </Text>
          </Flex>
          <Text variant="eventGridCardDate">{eventDate}</Text>
        </Flex>
        <Flex flexDirection="column" w="100%" gap="8px">
          <Flex flexDirection="column" flex="1">
            <Text variant="eventGridCardTitle">{event.name}</Text>
            <Text variant="eventGridCardSubTitle">
              {t(`event_types.${event.type}`)}
            </Text>
          </Flex>
          <Flex alignItems="center" gap="4px" flex="1">
            {event?.numberOfGuests && (
              <Flex alignItems="center" gap="2px">
                <Icon as={HiOutlineMailOpen} color="#00000099" />
                <Text variant="eventGridCardDesc">
                  {t('create_event.modals.found_event.invited', {
                    numberOfGuests: event?.numberOfGuests,
                  })}
                </Text>
              </Flex>
            )}
            <Flex gap="2px">
              <Icon as={IoLocationOutline} color="#00000099" />
              <Text variant="eventGridCardDesc">{event?.location?.label}</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap="8px" flex="1">
        {eventTabButtons.map((button, index) => (
          <EventTabButton
            key={index}
            title={button.title}
            max={button.max}
            current={button.current}
            type={button.type}
            flex="1"
            minW="auto"
            w="100%"
            h="100%"
          />
        ))}
      </Flex>
    </Flex>
  );
}
