import { createSlice } from '@reduxjs/toolkit';

const actionTrackerSlice = createSlice({
  name: 'actionTracker',
  initialState: { lastActionType: null },
  reducers: {},
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      state.lastActionType = action.type;
    });
  },
});

export default actionTrackerSlice.reducer;
