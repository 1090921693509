import { Flex, Icon, IconButton, Image, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import chatsEmptyIcon from 'assets/svg/messenger/chats-empty-icon.svg';
import { HSeparator } from 'components/separator/Separator';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import threadsService from 'services/thread.api';
import ThreadListItem from 'views/main/managment/messenger/components/ThreadListItem';
import ScaleIcon from '../../assets/svg/notification/scale.svg';
import { SearchBar } from './searchBar/SearchBar';
export default function Messages({ onClose }: { onClose: () => void }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  // fetch only the first 10 threadsResult?.data?.results for navigation bar
  const query = useMemo(() => {
    const query: { name?: string; limit: number } = {
      limit: 10,
    };
    if (search) {
      query['name'] = search;
    }
    return query;
  }, [search]);

  const threadsResult = useQuery({
    queryKey: ['fetchThreads-10'],
    queryFn: async () => await threadsService.getThreads(query),
  });

  return (
    <>
      <Flex
        w="100%"
        mb="20px"
        alignItems="center"
        justifyContent="space-between">
        <Text variant="cardTitle">{t('messenger.messages')}</Text>
        <Flex alignItems="center" gap="27px">
          <Icon as={FiMoreHorizontal} w="20px" h="20px" color="#8E7A70" />
          <Image src={ScaleIcon} alt="" w="18px" h="18px" cursor="pointer" />
          <IconButton
            bg="brand.400"
            aria-label="plus"
            w="24px"
            h="24px"
            minW="24px"
            cursor="pointer"
            icon={<Icon as={FaPlus} color="warmWhite.100" />}
          />
        </Flex>
      </Flex>
      <Flex
        w="100%"
        display={
          search.length > 0 || threadsResult?.data?.results.length !== 0
            ? 'flex'
            : 'none'
        }>
        <SearchBar
          placeholder={t('messenger.search_placeholder')}
          onChange={(e: any) => setSearch(e.target.value)}
          value={search}
          w="100%"
        />
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        overflowY="auto"
        maxH="60vh">
        {threadsResult?.data?.results.length === 0 && search.length === 0 && (
          <>
            <Image
              src={chatsEmptyIcon}
              w={90}
              h={70}
              alt="No Conversation Found"
              mb={14}
            />
            <Text fontSize={'2xl'} fontWeight="bold">
              {t('messenger.no_messages_found')}
            </Text>
            <Text mt={6} fontSize="sm" color="primary.100">
              {t('messenger.no_messages_found_description')}
            </Text>
          </>
        )}
        {threadsResult?.data?.results.map((thread, key) => (
          <Flex key={key} w="100%" flexDirection="column" gap="10px" mt={3}>
            <ThreadListItem
              thread={thread}
              bg="transparent"
              width="100%"
              onClick={() => {
                navigate(`/main/messenger/${thread.id}`);
                onClose();
              }}
            />
            <HSeparator />
          </Flex>
        ))}
      </Flex>
      <Text
        textAlign="center"
        mt={6}
        variant="eventLink"
        onClick={() => {
          navigate('/main/messenger');
          onClose();
        }}
        cursor="pointer">
        {t('messenger.go_to_messenger')}
      </Text>
    </>
  );
}
