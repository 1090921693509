import { Search2Icon } from '@chakra-ui/icons';
import { Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/system';
import { User } from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import AssignAutocompleteField from 'components/AssignAutocompleteField';
import AuthImage from 'components/authImage/AuthImage';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { RootState } from 'contexts/redux/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreVertical } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import UserService from 'services/user.api';

interface Props {
  currentAssignedInput: any;
  displayingAssigned: any;
  handleRemoveAssigned: any;
  handleSelectAssigned: any;
  setCurrentAssignedInput: any;
}
export default function ChatTopHeaderUserInfo(props: Props) {
  const {
    currentAssignedInput,
    displayingAssigned,
    handleRemoveAssigned,
    handleSelectAssigned,
    setCurrentAssignedInput,
  } = props;
  const { t } = useTranslation();
  const { newThread, currentThread } = useSelector((state: RootState) => ({
    newThread: state.messenger.newThread,
    currentThread: state.messenger.currentThread,
  }));
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const user = useMyUser();

  const participant = useMemo(
    () =>
      (currentThread?.participants as string[])?.find((p) => p !== user?.id) ||
      '',
    [currentThread?.participants, user?.id],
  );

  const participantResult = useQuery({
    queryKey: [`getParticipant-${participant}`],
    queryFn: () => UserService.getUser(participant),
    enabled: !!participant,
  });

  const assigned = useMemo(
    () =>
      (newThread?.participants as User[]) ||
      (participantResult.data && [participantResult.data]) ||
      [],
    [newThread?.participants, participantResult.data],
  );

  if (assigned.length > 0) {
    return (
      <HStack w="100%" justifyContent="space-between">
        <Flex gap="12px">
          <AuthImage
            path={
              assigned[0]?.media?.find((media: any) => media?.type === 'avatar')
                ?.url
            }
            isAvatar
            name={assigned[0]?.firstName + ' ' + assigned[0]?.lastName}
            w="32px"
            minW="32px"
            minH="32px"
            h="32px"
            borderRadius="50%"
          />
          <Flex flexDirection="column" alignItems="start">
            <Text
              variant="detailInfoValue"
              color="#1B2037"
              noOfLines={1}
              maxW="200px">
              {assigned[0]?.firstName + ' ' + assigned[0]?.lastName}
            </Text>
            <Text variant="eventCardSubTitle" color="#666666">
              Last seen 12 minutes ego
            </Text>
          </Flex>
        </Flex>
        <Flex alignItems="center" gap="23px">
          <Icon as={Search2Icon} color="#8E7A70" w="18px" h="18px" />
          <Icon as={FiMoreVertical} w="20px" h="20px" color="#8E7A70" />
        </Flex>
      </HStack>
    );
  }
  return (
    <AssignAutocompleteField
      labelProps={{ ms: '15px', mb: '0px' }}
      options={displayingAssigned}
      value={currentAssignedInput}
      borderRadius="full"
      h="44px"
      bg={inputBg}
      onSelected={handleSelectAssigned}
      selectedOptions={assigned.map((item: any) => item.id)}
      handleRemove={handleRemoveAssigned}
      onChange={(e) => setCurrentAssignedInput(e.target.value)}
      placeholder={t('messenger.chat.header.search_placeholder')}
      w="100%"
    />
  );
}
