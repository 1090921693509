import {
  Divider,
  Flex,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { setViewPhotoDialog } from 'contexts/redux/dialog/dialogsSlice';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LuDownload, LuPaperclip } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
import { IMessage } from 'services/@types';
import _uploadService from 'services/upload.api';

interface MessageBubbleProps {
  message: IMessage;
  previousMessage: IMessage;
  nextMessage: IMessage;
  index: number;
}
export const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  previousMessage,
  nextMessage,
  index,
}) => {
  const user = useMyUser();

  const showDateDivider = useMemo(
    () =>
      !previousMessage ||
      new Date(message?.createdAt)?.toDateString()?.split('T')[0] !==
        new Date(previousMessage?.createdAt)?.toDateString()?.split('T')[0],
    [message, previousMessage],
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleMediaClicked = useCallback(
    (item) => {
      dispatch(setViewPhotoDialog({ item: item }));
    },
    [dispatch],
  );

  const isMyMessage = useMemo(
    () => message.senderId === user.id,
    [message.senderId, user.id],
  );

  return (
    <VStack
      key={index.toString()}
      w="100%"
      spacing={0}
      gap={2}
      align={!isMyMessage ? 'flex-end' : 'flex-start'}
      alignSelf={!isMyMessage ? 'flex-end' : 'flex-start'}>
      {showDateDivider && (
        <Flex align="center" w="100%" py={2}>
          <Divider borderColor="#8E7A7080" flex={1} />
          <Text mx={4} fontSize="xs" color="gray.500">
            {moment(message.createdAt).format('MMM DD')}
          </Text>
          <Divider flex={1} borderColor="#8E7A7080" />
        </Flex>
      )}
      {message.text && (
        <Flex
          bg={isMyMessage ? '#8E7A70' : 'brand.400'}
          p={2}
          maxW={'70%'}
          borderRadius={'md'}>
          <Text
            key={message.threadId}
            fontSize="sm"
            color={isMyMessage ? 'white' : 'gray.900'}>
            {message.text}
          </Text>
        </Flex>
      )}
      {message?.media?.map((item, index) => (
        <Flex role="group" align={'center'} key={index}>
          {/* <Menu variant={'item'}>
            <MenuButton display={item.type === 'image' ? 'block' : 'none'}>
              <AuthImage
                path={item.url}
                w="120px"
                h="120px"
                borderRadius="8px"
              />
            </MenuButton>
            <MenuList sx={{ borderRadius: '16px', minWidth: 'fit-content' }}>
              <MenuItem
                icon={<Icon as={LuDownload} fontSize={18} />}
                onClick={() => {}}>
                Save to device
              </MenuItem>
              <Divider />
              <MenuItem
                icon={<Icon as={LuPaperclip} fontSize={18} />}
                onClick={() => {}}>
                Assign to Event
              </MenuItem>
            </MenuList>
          </Menu> */}
          <Flex
            bg={isMyMessage ? '#8E7A70' : 'brand.400'}
            boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
            p={0}
            mx={2}
            onClick={async () => {
              if (item.type === 'image') {
                handleMediaClicked(item);
              }
              if (item.type === 'document') {
              }
            }}
            cursor="pointer"
            borderRadius={item.type === 'image' ? 'md' : '3xl'}>
            {item.type === 'image' && (
              <AuthImage
                path={item.url}
                key={index}
                id={index.toString()}
                onClick={() => handleMediaClicked(item)}
                transition="all 0.2s"
                _hover={{ cursor: 'pointer', opacity: 0.8 }}
                src={item.url}
                alt={''}
                borderRadius={'md'}
                maxW={120}
                maxH={120}
              />
            )}
            {item.type !== 'image' && (
              <Flex borderRadius={'3xl'} py={2} px={4}>
                <Text>{item.displayName}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      ))}
      {/* {message.error && (
        <HStack spacing={1} px={2}>
          <Icon as={LuInfo} color="red.500" fontSize={12} />
          <Text
            color="red.500"
            fontSize="xs"
            fontWeight={400}
            display={message.error ? 'block' : 'none'}>
            {t('messenger.chat.error')}
          </Text>
        </HStack>
      )} */}
      <Text
        // display={!message.error ? 'block' : 'none'}
        display={'block'}
        px={1}
        fontSize="xs"
        fontWeight={400}
        color="gray.500">
        {moment(message.createdAt).format('hh:mm')}
      </Text>
    </VStack>
  );
};
