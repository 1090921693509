import { Flex, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import threadService from 'services/thread.api';
import ThreadListItem from 'views/main/managment/messenger/components/ThreadListItem';

export default function Messages() {
  const { t } = useTranslation();
  const params = useParams();

  const eventThreads = useQuery({
    queryKey: [`fetchEventThreads-${params?.id}`],
    queryFn: () =>
      threadService.getThreads({ eventId: params?.id, limit: 100 }),
    enabled: !!params?.id,
  });

  const navigate = useNavigate();
  return (
    <Card
      w={{ base: '100%' }}
      h="calc(100vh - 170px)"
      gap="28px"
      flex={1}
      borderRadius={{ base: '0px', md: '18px' }}>
      <Flex flexDirection="column" alignItems="start">
        <Text variant="cardTitle">{t('messages.title')}</Text>
        <Text variant="eventCardTitle">{t('messages.subtitle')}</Text>
      </Flex>
      <Flex flexDirection="column" overflowY="auto">
        {eventThreads?.data?.results?.map((thread, key) => (
          <Flex key={key} w="100%" flexDirection="column" gap="10px" mt={3}>
            <ThreadListItem
              thread={thread}
              bg="transparent"
              width="100%"
              onClick={() =>
                navigate(`/main/event/${params.id}/messenger/${thread.id}`)
              }
            />
            {eventThreads?.data?.results?.length - 1 !== key && <HSeparator />}
          </Flex>
        ))}
      </Flex>
    </Card>
  );
}
