// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import dialogsReducer from './dialog/dialogsSlice';
import businessReducer from './business/businessSlice';
import businessTagsReducer from './businessTags/businessTagsSlice';
import businessCategoriesReducer from './businessCategories/businessCategoriesSlice';
import supplierReducer from './supplier/supplierSlice';
import uploadReducer from './uploads/uploadSlice';
import inviteReducer from './invite/inviteSlice';
import albumReducer from './albums/albumSlice';
import mediaItemReducer from './mediaItem/mediaItemSlice';
import productReducer from './product/productSlice';
import eventReducer from './event/eventSlice';
import quoteReducer from './quote/quoteSlice';
import taskReducer from './task/taskSlice';
import meetingReducer from './meeting/meetingSlice';
import calendarReducer from './calendar/calendarsSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import integrationSlice from './integrations/integrationSlice';
import calendarEventSlice from './calendarEvent/calendarEventSlice';
import userPreferencesSlice from './userPreferences/userPreferencesSlice';
import messengerSlice from './messenger/messengerSlice';
import actionTrackerReducer from './actionTracker/actionTrackerSlice';
import pushNotificationTokenSlice from './pushNotificationToken/pushNotificationTokenSlice';
const ignoredActions = [
  'dialogs/setEventsDialog',
  'dialogs/setAskForQuoteDialog',
  'dashboard/updateSelectedDate',
  'events/getEvents/fulfilled',
  'tasks/getMeetings/fulfilled',
  'events/getEvents/pending',
  'tasks/getMeetings/pending',
  'calendarEvents/updateViewedCalendars/pending',
  'calendarEvents/updateViewedCalendars/fulfilled',
];

const ignoredPaths = [
  'dialogs.eventsDialog.onConfirm',
  'dashboard.selectedDate',
];

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    dialogs: dialogsReducer,
    businesses: businessReducer,
    businessCategories: businessCategoriesReducer,
    businessTags: businessTagsReducer,
    suppliers: supplierReducer,
    uploads: uploadReducer,
    invites: inviteReducer,
    album: albumReducer,
    mediaItem: mediaItemReducer,
    product: productReducer,
    event: eventReducer,
    quote: quoteReducer,
    task: taskReducer,
    meetings: meetingReducer,
    calendars: calendarReducer,
    dashboard: dashboardReducer,
    integration: integrationSlice,
    calendarEvent: calendarEventSlice,
    userPreferences: userPreferencesSlice,
    messenger: messengerSlice,
    pushNotificationToken: pushNotificationTokenSlice,
    actionTracker: actionTrackerReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: ignoredActions,
      //   ignoredActionPaths: ignoredPaths,
      //   ignoredPaths: ignoredPaths,
      // },
      serializableCheck: false,
    });
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
