import { useToast } from '@chakra-ui/react';
import { User } from '@sentry/react';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setEventsDialog } from 'contexts/redux/dialog/dialogsSlice';
import { createMeeting } from 'contexts/redux/meeting/meetingSlice';
import { fetchUsers, fetchUser } from 'contexts/redux/user/userSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IEvent, IMeeting, UrlItem } from 'services/@types';
const requiredFields = ['name', 'location', 'startDate', 'endDate'];
interface UseCreateMeetingResult {
  errorList: string[];
  meeting: Partial<IMeeting>;
  handleChangeMeeting: (e: any) => void;
  handleCreateMeeting: () => void;
  handleChangeRemind: (e: any) => void;
  selectedRemind: string[];
  event: IEvent;
  handleOpenEventDialog: () => void;
  displayingGuest: {
    avatar: UrlItem | undefined;
    email: string;
    label: string;
    value: string;
  }[];
  currentGuestInput: string;
  handleSelectGuest: (value: string) => void;
  handleRemoveGuest: (value: string) => void;
  setCurrentGuestInput: (value: string) => void;
}
export default function useCreateMeeting(): UseCreateMeetingResult {
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const myUser = useMyUser();
  const myBusiness = useMyBusiness();
  const [event, setEvent] = useState<IEvent>(null);
  const [meeting, setMeeting] = useState<Partial<IMeeting>>({
    startDate: new Date(),
    endDate: new Date(new Date().setHours(new Date().getHours() + 1)),
    guests: [],
  });
  const [errorList, setErrorList] = useState<string[]>([]);
  const selectedRemind = useMemo(
    () => [`create_meeeting.remind_${meeting?.reminder}`] || [],
    [meeting?.reminder],
  );
  const [currentGuestInput, setCurrentGuestInput] = useState<string>('');
  const [displayingGuest, setDisplayingGuest] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
    }[]
  >([]);
  const handleChangeMeeting = useCallback(
    (e: any) => {
      if (errorList.includes(e.target.name)) {
        setErrorList((prev) => prev.filter((field) => field !== e.target.name));
      }
      if (e.target.name === 'startTime' || e.target.name === 'endTime') {
        const [hours, minutes] = e.target.value.split(':');
        const newDate = new Date(meeting.startDate);
        newDate.setHours(hours, minutes);
        setMeeting((prev) => ({
          ...prev,
          [e.target.name === 'startTime' ? 'startDate' : 'endDate']: newDate,
        }));
        return;
      }
      setMeeting((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    },
    [errorList, meeting.startDate],
  );
  const validateTask = useCallback(() => {
    let result = requiredFields.filter(
      (field) => !meeting || !meeting[field as keyof IMeeting],
    );

    if (result.length) {
      setErrorList(result);
      return true;
    }
    return false;
  }, [meeting]);

  const handleCreateMeeting = useCallback(async () => {
    const isError = validateTask();
    if (isError) {
      return;
    }

    let newMeetingData: any = { ...meeting };
    if (event) {
      newMeetingData.eventId = event.id;
    }
    const newMeeting = await dispatch(
      createMeeting(newMeetingData as IMeeting),
    );
    if (newMeeting?.error) {
      return;
    }
    toast({
      title: t('create_meeting.success'),
      variant: 'main',
    });
    setEvent(null);
    setMeeting(null);
    navigate('/');
  }, [meeting, validateTask, dispatch, navigate, t, toast, event]);
  const handleChangeRemind = useCallback(
    (e: string) => {
      const split = e.split('_');
      const event = {
        target: {
          value: split[split.length - 1],
          name: 'reminder',
        },
      };
      handleChangeMeeting(event);
    },
    [handleChangeMeeting],
  );
  const handleOpenEventDialog = useCallback(() => {
    dispatch(
      setEventsDialog({
        onConfirm: (e: any) => {
          if (errorList.includes('event')) {
            setErrorList((prev) => prev.filter((field) => field !== 'event'));
          }
          setEvent(e);
        },
      }),
    );
  }, [dispatch, errorList]);

  const handleSelectGuest = useCallback(
    async (value: string) => {
      const { payload: user } = await dispatch(fetchUser(value));
      setMeeting((prev) => ({
        ...prev,
        guests: [...prev.guests, user.id],
      }));
      setCurrentGuestInput('');
    },
    [dispatch],
  );

  const handleRemoveGuest = (id: string) => {
    setMeeting((prev) => ({
      ...prev,
      guests: prev.guests.filter((item) => item !== id),
    }));
  };

  useEffect(() => {
    if (currentGuestInput.length < 1 || !currentGuestInput) {
      return;
    }
    const fetchSeats = async () => {
      const users = await dispatch(
        fetchUsers({
          name: currentGuestInput,
          limit: 5,
        }),
      );

      if (users?.payload?.results) {
        setDisplayingGuest(
          users.payload.results
            .filter(
              (user: User) => !meeting.guests.find((a: any) => a === user?.id),
            )
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
            })),
        );
      }
    };
    fetchSeats();
  }, [currentGuestInput, dispatch, myBusiness?.id, meeting.guests, myUser?.id]);
  return {
    errorList,
    meeting,
    handleChangeMeeting,
    handleCreateMeeting,
    handleChangeRemind,
    selectedRemind,
    event,
    handleOpenEventDialog,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleRemoveGuest,
    setCurrentGuestInput,
  };
}
