import {
  Collapse,
  Flex,
  Icon,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import DropdownField from 'components/fields/DropdownField';
import InputField from 'components/fields/InputField';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import useEventFilter from '../hooks/useEventFilter';
import LayoutSelector from './LayoutSelector';
import MobileFilterBurger from './MobileFilterBurger';
import RefreshFiltersButton from './RefreshFiltersButton';
const dropDownStyles = {
  flex: { base: 'none', md: '1' },
  width: { base: '100%', md: 'auto' },
  menuButton: {
    borderColor: '#0000001A',
    bg: 'transparent',
    color: '#999999',
    fontWeight: '500',
  },
  downIconProps: { color: '#939393' },
};
export const eventStatuses = [
  'new',
  'approved',
  'quotation',
  'completed',
  'post',
  'cancelled',
];
const eventTypes = [
  'wedding',
  'party',
  'corporate',
  'festival',
  'bat_bar_mitzva',
];
interface EventsOverviewHeaderProps {
  setActiveLayout: (layout: 'grid' | 'list') => void;
  activeLayout: 'grid' | 'list';
}
export default function EventsOverviewHeader(props: EventsOverviewHeaderProps) {
  const { setActiveLayout, activeLayout } = props;
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: isMobile ? false : true,
  });
  const {
    eventName,
    selectedEventType,
    selectedEventStatus,
    selectedMonth,
    options,
    showedMonth,
    handleEventNameChange,
    handleChangeEventType,
    handleChangeEventStatus,
    handleChangeMonth,
    handleRefreshFilters,
  } = useEventFilter();
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" gap="16px" w="100%">
      <MobileFilterBurger onToggle={onToggle} isOpen={isOpen} />
      <Collapse in={isOpen} animateOpacity>
        <Flex
          w="100%"
          alignItems="center"
          gap="16px"
          wrap={{ base: 'wrap', md: 'nowrap' }}>
          {isMobile && (
            <InputField
              placeholder={t('events_overview.header.search')}
              iconRight={
                <Icon
                  as={FiSearch}
                  color={dropDownStyles.downIconProps.color}
                />
              }
              value={eventName}
              onChange={handleEventNameChange}
              flex={dropDownStyles.flex}
              w={dropDownStyles.width}
              minW={dropDownStyles.width}
              borderColor={dropDownStyles.menuButton.borderColor}
              _placeholder={{ color: dropDownStyles.menuButton.color }}
            />
          )}
          <DropdownField
            placeholder={t('events_overview.header.status')}
            options={eventStatuses.map((type) => `event.status.${type}`)}
            selectedOptions={selectedEventStatus}
            onSelected={handleChangeEventStatus}
            showedOptions={t(selectedEventStatus[0])}
            closeOnSelect
            {...dropDownStyles}
          />
          <DropdownField
            placeholder={t('events_overview.header.event_type')}
            options={eventTypes.map((type) => `event_types.${type}`)}
            selectedOptions={selectedEventType}
            onSelected={handleChangeEventType}
            showedOptions={t(selectedEventType[0])}
            closeOnSelect
            {...dropDownStyles}
          />
          <DropdownField
            placeholder={t('events_overview.header.month')}
            options={options}
            selectedOptions={selectedMonth}
            showedOptions={showedMonth}
            onSelected={handleChangeMonth}
            {...dropDownStyles}
          />
          {!isMobile && (
            <InputField
              placeholder={t('events_overview.header.search')}
              iconRight={
                <Icon
                  as={FiSearch}
                  color={dropDownStyles.downIconProps.color}
                />
              }
              value={eventName}
              onChange={handleEventNameChange}
              flex={dropDownStyles.flex}
              w={dropDownStyles.width}
              minW={dropDownStyles.width}
              borderColor={dropDownStyles.menuButton.borderColor}
              _placeholder={{ color: dropDownStyles.menuButton.color }}
            />
          )}
          <RefreshFiltersButton onClick={handleRefreshFilters} />
          {!isMobile && (
            <LayoutSelector
              activeLayout={activeLayout}
              onLayoutChange={(layout: 'grid' | 'list') =>
                setActiveLayout(layout)
              }
            />
          )}
        </Flex>
      </Collapse>
    </Flex>
  );
}
