import { AxiosInstance, AxiosResponse } from 'axios';
import { Thread, PaginatedResults } from './@types';
import { createAxiosInstance } from './axios.util';

class threadService {
  private api: AxiosInstance;

  constructor(baseURL: string) {
    this.api = createAxiosInstance(baseURL);
  }

  public async createThread(Thread: Thread): Promise<Thread> {
    const response: AxiosResponse<Thread> = await this.api.post(
      '/thread',
      Thread,
    );
    return response.data;
  }

  public async getThreads(
    queryParams?: Record<string, any>,
  ): Promise<PaginatedResults<Thread>> {
    const response: AxiosResponse<PaginatedResults<Thread>> =
      await this.api.get('/thread', {
        params: queryParams,
      });
    return response.data;
  }

  public async getThread(threadId: string): Promise<Thread> {
    const response: AxiosResponse<Thread> = await this.api.get(
      `/thread/${threadId}`,
    );
    return response.data;
  }

  public async updateThread(
    threadId: string,
    updates: Partial<Thread>,
  ): Promise<Thread> {
    const response: AxiosResponse<Thread> = await this.api.patch(
      `/thread/${threadId}`,
      updates,
    );
    return response.data;
  }

  public async deleteThread(threadId: string): Promise<void> {
    await this.api.delete(`/thread/${threadId}`);
  }
}

const _threadService = new threadService(
  process.env.REACT_APP_SERVER_API_URL || 'http://localhost:3000/v1',
);

export default _threadService;
