import {
  Button,
  Flex,
  HStack,
  Icon,
  ScaleFade,
  Switch,
  Text,
  useClipboard,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import CalendarsDropDown from 'components/calendarsDropDown';
import EventCard from 'components/eventsDialog/eventCard';
import DropdownField from 'components/fields/DropdownField';
import InputDateField from 'components/fields/InputDateField';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import LocationAutocomplete from 'components/locationAutocomplete';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';
import { IoIosAlert } from 'react-icons/io';
import { PiCopySimple } from 'react-icons/pi';
import useCreateMeeting from '../hooks/useCreateMeeting';
import AssignAutocompleteField from 'components/AssignAutocompleteField';
const remindTypes = ['none', '10', '30'];
export default function CreateMeetingContainer() {
  const { t, i18n } = useTranslation();
  const { onToggle, isOpen } = useDisclosure();
  const {
    errorList,
    handleChangeMeeting,
    handleCreateMeeting,
    meeting,
    handleChangeRemind,
    selectedRemind,
    handleOpenEventDialog,
    event,
    displayingGuest,
    currentGuestInput,
    handleSelectGuest,
    handleRemoveGuest,
    setCurrentGuestInput,
  } = useCreateMeeting();
  const { onCopy, hasCopied } = useClipboard(event?.meetLink);
  return (
    <VStack alignItems="start" w={{ base: '100%', md: '60%' }} gap="26px">
      <HStack justifyContent="space-between" align="center" w="100%">
        <Text variant="cardTitle">{t('create_meeting.title')}</Text>
        {event && (
          <EventCard
            event={event}
            onClick={handleOpenEventDialog}
            isQuoteEvent
            // flex="1"
            maxW={{ base: '100%', md: '320px' }}
            w={{ base: '100%', md: 'fit-content' }}
          />
        )}
        {!event && (
          <Button
            variant="h1cta"
            w={{ base: '100%', md: 'fit-content' }}
            onClick={handleOpenEventDialog}
            maxW={{ base: '100%', md: '320px' }}
            flex="1"
            px={24}>
            {t('create_meeting.assign_to_event')}
          </Button>
        )}
      </HStack>
      <Flex wrap="wrap" gap="21px" w="100%">
        <InputField
          label={t('create_meeting.create_meeting')}
          placeholder={t('create_meeting.create_meeting')}
          name="name"
          flex="1"
          w={{ base: '100%', md: '310px' }}
          containerStyles={{ w: { base: '100%', md: '310px' } }}
          value={meeting?.name || ''}
          onChange={handleChangeMeeting}
          borderColor={errorList.includes('name') ? 'error.100' : 'gray.200'}
        />
        <CalendarsDropDown
          alignSelf="self-end"
          isMeeting
          w={{ base: '100%', md: '310px' }}
          value={meeting?.calendarId}
          onChange={(value: string) => {
            handleChangeMeeting({
              target: {
                value,
                name: 'calendarId',
              },
            });
          }}
        />

        <LocationAutocomplete
          value={meeting?.location || ''}
          onChange={(e) => {
            const locationEvent = {
              target: {
                value: e,
                name: 'location',
              },
            };
            handleChangeMeeting(locationEvent);
          }}
          containerStyles={{
            '@media (min-width: 768px)': { width: '310px' },
            zIndex: 1,
          }}
          isIconHidden
          label={t('create_meeting.location')}
          placeholder={t('create_meeting.location')}
          controlStyles={{
            borderColor: errorList.includes('location') ? '#FF554A' : '',
            width: '100%',
          }}
          flexContainer={{ w: '100%' }}
        />
      </Flex>
      <Flex wrap="wrap" gap="20px" alignItems="end">
        <Flex flex="1" gap="20px" alignItems="end">
          <InputDateField
            name="startDate"
            label={t('create_meeting.meeting_date')}
            value={meeting?.startDate || new Date()}
            onChange={(e: Date) => {
              const event = {
                target: {
                  value: e,
                  name: 'startDate',
                },
              };
              handleChangeMeeting(event);
            }}
            justifyContent="end"
            containerStyles={{
              w: { base: '100%', md: '166px' },
            }}
            buttonStyles={{
              borderColor: errorList.includes('startDate')
                ? 'error.100'
                : 'general.100',
              w: { base: '100%', md: '166px' },
            }}
          />
          <InputField
            label={t('create_meeting.start_meeting')}
            placeholder={t('create_meeting.start_meeting')}
            name="startTime"
            type="time"
            w={{ base: '100%', md: '126px' }}
            containerStyles={{ maxW: { base: '126px', md: '100%' } }}
            value={moment(meeting?.startDate).format('HH:mm')}
            onChange={handleChangeMeeting}
            borderColor={
              errorList.includes('startTime') ? 'error.100' : 'general.100'
            }
          />
        </Flex>
        <Flex flex="1" gap="20px" alignItems="end">
          <InputField
            label={t('create_meeting.end_meeting')}
            placeholder={t('create_meeting.end_meeting')}
            name="endTime"
            type="time"
            w={{ base: '100%', md: '126px' }}
            value={moment(meeting?.endDate).format('HH:mm')}
            onChange={handleChangeMeeting}
            borderColor={
              errorList.includes('endTime') ? 'error.100' : 'general.100'
            }
            containerStyles={{ maxW: { base: '126px', md: '100%' } }}
          />
          <DropdownField
            closeOnSelect
            options={remindTypes.map((type) => `create_meeting.remind_${type}`)}
            onSelected={handleChangeRemind}
            selectedOptions={selectedRemind}
            placeholder={
              meeting?.reminder
                ? t(`create_meeting.remind_${meeting.reminder}`)
                : t('create_meeting.remind_me')
            }
            label={t('create_meeting.remind_me')}
            w={{ base: '100%', md: '166px' }}
            formLabelStyles={{ mb: 1 }}
            menuButton={{
              borderColor: 'general.100',
              h: '50px',
              borderRadius: '16px',
            }}
          />
        </Flex>
        <TextField
          label={t('create_meeting.note')}
          placeholder={t('create_meeting.note')}
          w="100%"
          minH="150px"
          resize="none"
          name="description"
          value={meeting?.description || ''}
          onChange={handleChangeMeeting}
        />
        <AssignAutocompleteField
          options={displayingGuest}
          value={currentGuestInput}
          onSelected={handleSelectGuest}
          selectedOptions={meeting?.guests}
          handleRemove={handleRemoveGuest}
          onChange={(e) => setCurrentGuestInput(e.target.value)}
          label={t('create_meeting.add_guests')}
          placeholder={t('create_meeting.add_guests')}
          w="100%"
        />
        {event?.meetLink && (
          <VStack alignItems="start" gap="12px">
            <Flex gap="12px" alignItems="center">
              <Switch dir={i18n.dir()} onChange={onToggle} variant="brand" />
              <Text variant="cardSectionTitle">
                {t('create_meeting.google_meets_link')}
              </Text>
            </Flex>
            <ScaleFade initialScale={0.9} in={isOpen}>
              <HStack bg="#9393930D" borderRadius="8px" p="4px 8px" gap="12px">
                <Text variant="cardSectionTitle">{event?.meetLink}</Text>
                <Icon
                  as={hasCopied ? FaCheck : PiCopySimple}
                  color="#AA915D"
                  w="20px"
                  h="20px"
                  cursor="pointer"
                  onClick={onCopy}
                />
              </HStack>
            </ScaleFade>
          </VStack>
        )}
        <HStack
          justifyContent="end"
          w="100%"
          flexDirection={{ base: 'column-reverse', md: 'row' }}
          gap={2}>
          {errorList.length > 0 && (
            <HStack justify="center">
              <Icon
                as={IoIosAlert}
                w="16px"
                h="16px"
                bg="white"
                color="error.100"
                borderRadius="50%"
              />
              <Text variant="errorTitle">{t('create_event.error')}</Text>
            </HStack>
          )}
          <Button
            variant="h1cta"
            minH={{ base: '50px', md: 'fit-content' }}
            borderRadius="16px"
            w={{ base: '100%', md: '140px' }}
            isDisabled={errorList.length > 0}
            onClick={handleCreateMeeting}>
            {t('create_meeting.create_meeting')}
          </Button>
        </HStack>
      </Flex>
    </VStack>
  );
}
