// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDBC6PGowOm_CfvBIW-5eVdzGSc4O_B28o',
  authDomain: 'brilliant-crm.firebaseapp.com',
  projectId: 'brilliant-crm',
  storageBucket: 'brilliant-crm.appspot.com',
  messagingSenderId: '876291263637',
  appId: '1:876291263637:web:77bf7dd74fac220bb05f9c',
  measurementId: 'G-GGNK3190N4',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
export { analytics, messaging };
