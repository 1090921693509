import { Flex, Spinner, VStack } from '@chakra-ui/react';
import {
  fetchMessages,
  fetchMessagesOnScroll,
} from 'contexts/redux/messenger/messengerSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageBubble } from './MessageBubble';

export const ChatMessagesSection: React.FC = () => {
  const dispatch = useDispatch<any>();
  const {
    messages,
    currentThread,
    messengerLoading,
    uploadLoading,
    page,
    totalPages,
  } = useSelector((state: RootState) => ({
    messages: state.messenger.messages,
    messengerLoading: state.messenger.loading,
    uploadLoading: state.uploads.loading,
    currentThread: state.messenger.currentThread,
    totalPages: state.messenger.messagePagination.totalPages,
    page: state.messenger.messagePagination.page,
  }));
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const { lastActionType } = useSelector(
    (state: RootState) => state.actionTracker,
  );

  const isLoading = useMemo(
    () => messengerLoading || uploadLoading,
    [messengerLoading, uploadLoading],
  );

  const fetchMoreMessages = useCallback(() => {
    if (currentThread?.id && page < totalPages) {
      dispatch(
        fetchMessagesOnScroll({
          threadId: currentThread.id,
          sortBy: '-createdAt',
          page: page + 1,
        }),
      );
    }
  }, [page, currentThread?.id, dispatch, totalPages]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;

    if (container && container.scrollTop === 0 && container.scrollHeight > 0) {
      console.log('scrolling to top: ', container.scrollTop);
      fetchMoreMessages();
    }
  }, [fetchMoreMessages]);

  useEffect(() => {
    if (currentThread?.id) {
      dispatch(
        fetchMessages({
          threadId: currentThread.id,
          sortBy: '-createdAt',
        }),
      );
    }
  }, [dispatch, currentThread?.id]);

  useEffect(() => {
    console.log('lastActionType', lastActionType);
    // scroll to bottom when new messages are fetched or created
    if (
      lastActionType === 'messenger/fetchMessages/fulfilled' ||
      lastActionType === 'messenger/createMessage/fulfilled'
    ) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 300);
    }
    if (lastActionType === 'messenger/fetchMessagesOnScroll/fulfilled') {
      if (containerRef?.current?.children[page * 5]) {
        setTimeout(() => {
          containerRef?.current?.children[page * 5].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 300);
      }
    }
  }, [lastActionType, page]);

  return (
    <VStack
      w="100%"
      py={4}
      h="100%"
      spacing={0}
      align="flex-start"
      gap={3}
      ref={containerRef}
      onScroll={handleScroll}
      overflow="scroll">
      {isLoading && (
        <Flex justifyContent="center" align="center" w="100%" h="100%">
          <Spinner />
        </Flex>
      )}
      {!isLoading &&
        messages?.map((message, index) => {
          const prevMessage = messages[index - 1];

          return (
            <MessageBubble
              index={index}
              key={index}
              message={message}
              previousMessage={prevMessage}
              nextMessage={messages[index + 1]}
            />
          );
        })}
      <Flex ref={messagesEndRef} />
    </VStack>
  );
};
