import { Flex, HStack } from '@chakra-ui/react';
import { RootState } from 'contexts/redux/store';
import { useSelector } from 'react-redux';
import TaskList from '../dashboard/components/TaskList';
import Messages from '../managment/events/eventInfo/components/Messages';
import Tracker from '../managment/events/eventInfo/components/Tracker';
import { EventPageTopNavigationBar } from './components/EventPageTopNavigationBar';
export const EventPage: React.FC = () => {
  const event = useSelector((state: RootState) => state.event.event);
  return (
    <Flex
      direction={{ base: 'column', xl: 'row' }}
      height="100%"
      pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" height="100%" w="100%" gap={5}>
        <Flex gap={5} wrap={{ base: 'wrap', md: 'nowrap' }} w="100%">
          <EventPageTopNavigationBar event={event} />
        </Flex>
        <HStack flexDirection={{ base: 'column', md: 'row' }} gap={2}>
          <TaskList />
          <Tracker />
          <Messages />
        </HStack>
      </Flex>
    </Flex>
  );
};
