import {
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function KeepHistory() {
  const { t } = useTranslation();
  const [value, setValue] = useState<'1w' | '2w' | '1m'>('1w');
  return (
    <Flex flexDir="column" alignItems="start" gap="16px">
      <HStack gap="16px">
        <Text variant="settingItemTitle">
          {t(`notification_settings_item.title.keep_history`)}
        </Text>
      </HStack>
      <RadioGroup
        onChange={(e: '1w' | '2w' | '1m') => setValue(e)}
        value={value}>
        <VStack alignItems="start" fontWeight="500" gap="16px">
          <Radio variant="brandOutline" value="1w">
            {t('notification_settings_item.1_w')}
          </Radio>
          <Radio variant="brandOutline" value="2w">
            {t('notification_settings_item.2_w')}
          </Radio>
          <Radio variant="brandOutline" value="1m">
            {t('notification_settings_item.1_m')}
          </Radio>
        </VStack>
      </RadioGroup>
    </Flex>
  );
}
