import { VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { HSeparator } from 'components/separator/Separator';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { useMyBusiness } from 'contexts/redux/business/businessSlice';
import { setNewThread } from 'contexts/redux/messenger/messengerSlice';
import { RootState } from 'contexts/redux/store';
import { fetchUser, fetchUsers } from 'contexts/redux/user/userSlice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UrlItem, User } from 'services/@types';
import ChatTopHeaderTitleAndEvent from './ChatTopHeaderTitleAndEvent';
import ChatTopHeaderUserInfo from './ChatTopHeaderUserInfo';
import threadsService from 'services/thread.api';
export const ChatTopHeader: React.FC = () => {
  const dispatch = useDispatch<any>();
  const myUser = useMyUser();
  const { newThread, currentThread } = useSelector((state: RootState) => ({
    newThread: state.messenger.newThread,
    currentThread: state.messenger.currentThread,
  }));

  const [currentAssignedInput, setCurrentAssignedInput] = useState<string>('');
  const [displayingAssigned, setDisplayingAssigned] = useState<
    {
      avatar: UrlItem | undefined;
      email: string;
      label: string;
      value: string;
    }[]
  >([]);
  const handleSelectAssigned = useCallback(
    async (value: string) => {
      const { payload: user } = await dispatch(fetchUser(value));
      dispatch(setNewThread({ participants: [user] }));
      setCurrentAssignedInput('');
    },
    [dispatch],
  );

  const threads = useQuery({
    queryKey: ['fetchThreadsForChatTopHeader'],
    queryFn: () => threadsService.getThreads({ limit: 100 }),
  });

  const participants = useMemo(() => {
    if (threads.data?.results && myUser?.id) {
      return threads.data?.results?.map((thread: any) =>
        thread?.participants?.find((p: any) => p !== myUser?.id),
      );
    }

    return [];
  }, [threads.data?.results, myUser?.id]);

  const myBusiness = useMyBusiness();

  useEffect(() => {
    if (currentAssignedInput.length < 1 || !currentAssignedInput) {
      return;
    }
    const fetchSeats = async () => {
      const users = await dispatch(
        fetchUsers({
          name: currentAssignedInput,
          limit: 5,
        }),
      );

      if (users?.payload?.results?.length > 0) {
        setDisplayingAssigned(
          users.payload.results
            .filter(
              (user: User) =>
                !participants?.find(
                  (participantId: string) => participantId === user?.id,
                ),
            )
            .filter(
              (user: User) =>
                !(newThread?.participants as User[])?.find(
                  (participant: User) => participant?.id === user?.id,
                ),
            )
            .filter((user: User) => user.id !== myUser?.id)
            .map((user: User) => ({
              avatar: user.media?.find(
                (media: UrlItem) => media.type === 'avatar',
              ),
              email: user.email,
              label: `${user.firstName} ${user.lastName}`,
              value: user?.id,
            })),
        );
      }
    };
    fetchSeats();
  }, [
    participants,
    currentAssignedInput,
    dispatch,
    myBusiness?.id,
    myUser?.id,
    newThread?.participants,
  ]);

  const handleRemoveAssigned = useCallback(
    (_: string) => {
      dispatch(
        setNewThread({
          assigned: [],
        }),
      );
    },
    [dispatch],
  );

  if (!currentThread && !newThread) {
    return null;
  }
  return (
    <VStack w="100%" gap="16px">
      <ChatTopHeaderTitleAndEvent />
      {(currentThread?.eventId ||
        currentThread?.title ||
        currentThread?.creatorUserId === myUser?.id ||
        newThread?.participants?.length > 0) && <HSeparator bg="#8E7A7080" />}
      <ChatTopHeaderUserInfo
        currentAssignedInput={currentAssignedInput}
        displayingAssigned={displayingAssigned}
        handleRemoveAssigned={handleRemoveAssigned}
        handleSelectAssigned={handleSelectAssigned}
        setCurrentAssignedInput={setCurrentAssignedInput}
      />
    </VStack>
  );
};
