import { Flex, HStack, Text } from '@chakra-ui/layout';
import { CalendarEvent } from 'services/@types';

export default function HolidayCard(prors: {
  calendarHoliday?: CalendarEvent;
}) {
  const { calendarHoliday } = prors;
  return (
    <HStack
      borderColor={calendarHoliday?.color}
      borderRadius="16px"
      borderStyle="solid"
      borderWidth="1px"
      w="100%"
      padding="14px 24px">
      <Flex flexDirection="column" alignItems="start" w="100%">
        <Text
          variant="cardSectionInfo"
          ps={0}
          wordBreak="break-all"
          noOfLines={1}>
          {calendarHoliday?.summary}
        </Text>
      </Flex>
    </HStack>
  );
}
