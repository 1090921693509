import { Button, Flex, Image, Text, VStack } from '@chakra-ui/react';
import Icon from 'assets/svg/notification/askingForQuote.svg';

export default function AskingForQuoteItem(props: { invite: any }) {
  const { invite } = props;
  return (
    <Flex
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="16px 8px"
      flexDirection="column"
      gap="36px">
      <Flex alignItems="center" gap="12px" p="0 5px">
        <Image src={Icon} alt="" w="15px" h="12px" />
        <Text variant="cardSubTitle" color="#1B2037">
          <span style={{ fontWeight: 700 }}>{invite?.fullName || 'Ana'}</span>{' '}
          is asking for a quote
        </Text>
      </Flex>
      <VStack gap="12px">
        <Button variant="h1cta" w="100%">
          View request
        </Button>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          8 Feb
        </Text>
      </VStack>
    </Flex>
  );
}
