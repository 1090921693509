import { Flex, Image, Text } from '@chakra-ui/react';
import Icon from 'assets/svg/notification/taskAssigned.svg';

export default function TaskAssignedItem() {
  return (
    <Flex
      // border="1px solid #F9FAFB"
      bg="#93C3B31A"
      borderRadius="12px"
      p="16px 8px"
      flexDirection="column">
      <Flex alignItems="center" gap="12px">
        <Image src={Icon} alt="" w="20px" h="20px" />
        <Text variant="cardSubTitle" color="#1B2037">
          You got a task assigned to you
        </Text>
      </Flex>
      <Text fontSize="md" color="gray.500" alignSelf="flex-end">
        1 min ago
      </Text>
    </Flex>
  );
}
