import { Flex, Switch, Text, useDisclosure } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useTranslation } from 'react-i18next';
import TrackItem from './TrackItem';
const trackItems: { title: string; time: string }[] = [
  { title: 'Event created', time: '3 days ago' },
  { title: 'contract', time: '2 days ago' },
  { title: 'Extras added', time: '1 days ago' },
  { title: 'Quote sent', time: '4 hours ago' },
];
export default function Tracker() {
  const { t, i18n } = useTranslation();
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Card
      w={{ base: '100%' }}
      h="calc(100vh - 170px)"
      flex={1}
      gap="28px"
      borderRadius={{ base: '0px', md: '18px' }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text variant="cardTitle">{t('tracker.title')}</Text>
        <Flex alignItems="center" gap="8px">
          <Text variant="eventSubTitle" fontSize="14px">
            {t('tracker.show_notes')}
          </Text>
          <Switch
            dir={i18n.dir()}
            variant="brand"
            checked={isOpen}
            onChange={onToggle}
          />
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap="5px" overflowY="auto">
        {trackItems.map((item, index) => (
          <TrackItem
            key={index}
            title={item.title}
            time={item.time}
            isLast={trackItems.length - 1 === index}
            isNoteVisible={isOpen}
          />
        ))}
      </Flex>
    </Card>
  );
}
