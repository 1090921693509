import { useToast } from '@chakra-ui/react';
import { messaging, onForegroundMessage } from 'config/firebase';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { sendPushNotificationToken } from 'contexts/redux/pushNotificationToken/pushNotificationTokenSlice';
import { RootState } from 'contexts/redux/store';
import { getToken } from 'firebase/messaging';

import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const PushNotifications = () => {
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const myUser = useMyUser();
  const sentToken = useRef(false);
  const { token } = useSelector(
    (state: RootState) => state.pushNotificationToken,
  );

  const handleNotificationReceived = useCallback(
    (payload: any) => {
      console.log('@@ Foreground notification received : ', payload);
      const { notification, data } = payload;
      const { title, body } = notification;
      console.log('@@ Notification : ', notification, data);

      toast({
        title: title,
        position: 'top-right',
        description: body,
        status: 'info',
      });
    },
    [toast],
  );

  useEffect(() => {
    if (!messaging || !myUser || token || sentToken.current) return;

    console.log('@@ Registering push notifications');
    const sendToken = async () => {
      const permission = await Notification.requestPermission();
      console.log(`@@ Permission : ${permission}`);
      if (permission === 'granted') {
        sentToken.current = true;
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_PUBLIC_KEY,
        });
        console.log('@@ Token generated : ', token);
        dispatch(
          sendPushNotificationToken({
            device: navigator.userAgent,
            token,
            userId: myUser?.id,
          }),
        );
      } else if (permission === 'denied') {
        alert('You denied for the notification');
      }
    };
    sendToken();
    onForegroundMessage().then((payload) => {
      console.log('@@ Foreground message received : ', payload);
      handleNotificationReceived(payload);
    });
  }, [dispatch, handleNotificationReceived, myUser, token]);
  return <></>;
};
