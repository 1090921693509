import './assets/css/App.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { loadGAScript } from 'scripts/ga';
import { registerServiceWorker } from 'scripts/fcm-register';
import { onMessage } from 'firebase/messaging';
import { messaging } from 'config/firebase';

// load ga script and send data
loadGAScript();
registerServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
