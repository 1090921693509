import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import NoNotification from 'assets/img/systemNotification/noNotification.png';
import Card from 'components/card/Card';
import { AnimatePresence, motion } from 'framer-motion';
import { RootState } from 'contexts/redux/store';
import { getTasks } from 'contexts/redux/task/taskSlice';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ITask } from 'services/@types';
import TaskCard from './TaskCard';
import { memo } from 'react';

export default function TaskList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tasks } = useSelector((state: RootState) => state.task);
  const dispatch = useDispatch<any>();

  const { isLoading } = useQuery({
    queryKey: ['tasks'],
    queryFn: async () => await dispatch(getTasks({})),
  });

  const SystemNotification = () => {
    return (
      <>
        <HStack justifyContent="start">
          <Text variant="eventCardTitle">{t('tasks.system_notification')}</Text>
        </HStack>
        <Image
          alignSelf="center"
          src={NoNotification}
          alt=""
          mt="24px"
          w="214px"
          h="168.04px"
        />
        <HStack justifyContent="center" pb="50px">
          <Text variant="taskCardSubTitle" color="brand.300">
            {t('tasks.no_system_notification')}
          </Text>
        </HStack>
      </>
    );
  };

  const Tasks = memo(() => {
    return (
      <>
        <HStack justifyContent="space-between" mb="8px">
          <Text variant="eventCardTitle">{t('tasks.personal_list')}</Text>
          {tasks?.length > 0 && (
            <IconButton
              bg="brand.400"
              aria-label="plus"
              w="24px"
              h="24px"
              minW="24px"
              cursor="pointer"
              onClick={() => navigate(`/main/tasks/create`)}
              icon={<Icon as={FaPlus} color="warmWhite.100" />}
            />
          )}
        </HStack>
        {isLoading && (
          <HStack justifyContent="center" height="auto" align="center">
            <Spinner />
          </HStack>
        )}
        <VStack gap="8px" pb="17px">
          {tasks?.length === 0 && (
            <VStack pt="50px" height="100%" w="100%">
              <Text variant="taskCardSubTitle">{t('tasks.no_tasks')}</Text>
              <Button
                onClick={() => navigate('/main/tasks/create')}
                variant="h1cta">
                {t('tasks.create_new_task')}
              </Button>
            </VStack>
          )}
          {tasks?.map((task: ITask) => (
            <TaskCard key={task.id} task={task} />
          ))}
        </VStack>
      </>
    );
  });
  return (
    <Card
      flexDirection="column"
      w="100%"
      // minW={310}
      flex={1}
      borderRadius={{ base: 0, md: '30px' }}
      p="31px 26px 0"
      height="100%"
      // maxH="580px"
      overflow="hidden"
      gap={3}>
      <Text variant="cardTitle">{t('tasks.title')}</Text>
      <Tabs variant="task" isLazy={true} height="100%" overflowY="auto">
        <TabList>
          <Tab>{t('tasks.tab.all')}</Tab>
          <Tab>{t('tasks.tab.system_notifications')}</Tab>
          <Tab>{t('tasks.tab.personal')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel as={AnimatePresence} mode="wait">
            <Flex
              pt="20.5px"
              as={motion.div}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
              flexDirection="column">
              {SystemNotification()}
              <Tasks />
            </Flex>
          </TabPanel>
          <TabPanel as={AnimatePresence} mode="wait">
            <Flex
              pt="20.5px"
              as={motion.div}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
              flexDirection="column">
              {SystemNotification()}
            </Flex>
          </TabPanel>
          <TabPanel as={AnimatePresence} mode="wait">
            <Flex
              pt="20.5px"
              as={motion.div}
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.98 }}
              flexDirection="column">
              <Tasks />
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}
