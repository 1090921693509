// event": {
//     "top_bar_tabs": {
//       "main": "ראשי",
//       "client_file": "קובץ לקוח",
//       "finance": "פיננסים",
//       "supplier_assignment": "הקצאת ספקים",
//       "sub_tabs": {
//         "tasks": "משימות",
//         "tracker": "מעקב",
//         "messages": "הודעות",
//         "details": "פרטים",
//         "quote": "הצעת מחיר",
//         "my_contact": "איש קשר שלי",
//         "line_up": "סדר הופעה",
//         "income": "הכנסות",
//         "outcome": "הוצאות",
//         "profit": "רווח",
//         "meetings": "פגישות",
//         "participants": "משתתפים",
//         "contracts": "חוזים"
import {
  Hide,
  HStack,
  Icon,
  IconButton,
  Image,
  Show,
  Text,
  VStack,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdLocationPin } from 'react-icons/md';
import { IEvent } from 'services/@types';

import clientFileIcon from 'assets/svg/event/top_bar/client_file.svg';
import contractsIcon from 'assets/svg/event/top_bar/contracts.svg';
import detailsIcon from 'assets/svg/event/top_bar/details.svg';
import financeIcon from 'assets/svg/event/top_bar/finance.svg';
import incomeIcon from 'assets/svg/event/top_bar/income.svg';
import lineUpIcon from 'assets/svg/event/top_bar/line_up.svg';
import mainIcon from 'assets/svg/event/top_bar/main.svg';
import meetingsIcon from 'assets/svg/event/top_bar/meetings.svg';
import messagesIcon from 'assets/svg/event/top_bar/messages.svg';
import myContractIcon from 'assets/svg/event/top_bar/my_contract.svg';
import outcomeIcon from 'assets/svg/event/top_bar/outcome.svg';
import participantsIcon from 'assets/svg/event/top_bar/participants.svg';
import profitIcon from 'assets/svg/event/top_bar/profit.svg';
import quoteIcon from 'assets/svg/event/top_bar/quote.svg';
import supplierAssignmentIcon from 'assets/svg/event/top_bar/supplier_assignment.svg';
import tasksIcon from 'assets/svg/event/top_bar/tasks.svg';
import trackerIcon from 'assets/svg/event/top_bar/tracker.svg';
import eventBriefIcon from 'assets/svg/event/top_bar/event_brief.svg';
import { setCurrentEventTab } from 'contexts/redux/event/eventSlice';
import { RootState } from 'contexts/redux/store';
import { FiPlus } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

interface EventPageTopNavigationBarProps {
  event: IEvent;
  currentTab?: string;
}
const NavigationTabs = {
  main: {
    icon: mainIcon,
    // tabs: ['tasks', 'tracker', 'messages'],
    tabs: [
      {
        name: 'tasks',
        icon: tasksIcon,
      },
      {
        name: 'tracker',
        icon: trackerIcon,
      },
      {
        name: 'messages',
        icon: messagesIcon,
      },
    ],
  },
  client_file: {
    icon: clientFileIcon,
    tabs: [
      {
        name: 'details',
        icon: detailsIcon,
      },
      {
        name: 'event_brief',
        icon: eventBriefIcon,
      },
      {
        name: 'quote',
        icon: quoteIcon,
      },
      {
        name: 'my_contract',
        icon: myContractIcon,
      },
      {
        name: 'line_up',
        icon: lineUpIcon,
      },
    ],
  },
  finance: {
    icon: financeIcon,
    tabs: [
      {
        name: 'income',
        icon: incomeIcon,
      },
      {
        name: 'outcome',
        icon: outcomeIcon,
      },
      {
        name: 'profit',
        icon: profitIcon,
      },
    ],
  },
  supplier_assignment: {
    icon: supplierAssignmentIcon,
    tabs: [
      {
        name: 'meetings',
        icon: meetingsIcon,
      },
      {
        name: 'participants',
        icon: participantsIcon,
      },
      {
        name: 'contracts',
        icon: contractsIcon,
      },
    ],
  },
};

export const EventPageTopNavigationBar: React.FC<
  EventPageTopNavigationBarProps
> = ({ event }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTab = useSelector(
    (state: RootState) => state.event.currentEventTab,
  );
  return (
    <Card
      h="fit-content"
      borderRadius={{
        base: 'none',
        md: '2xl',
      }}>
      <HStack
        w="100%"
        align="start"
        gap={6}
        flexDirection={{ base: 'column', md: 'row' }}
        spacing={0}>
        <VStack
          spacing={0}
          w={{ base: '100%', md: 'fit-content' }}
          align={'start'}>
          <HStack w="100%">
            <Text
              w="100%"
              textAlign={'center'}
              fontSize="10px"
              py={1.5}
              fontWeight={'bold'}
              bg="#5FBF96"
              flex={1}
              color="white"
              borderRadius={'10px'}>
              Approved
            </Text>
            <Show below="md">
              <Text flex={1} fontSize="sm" fontWeight={'500'}>
                {event?.dateAndTime || '12/12/2021 12:00'}
              </Text>
            </Show>
          </HStack>
          <Text fontSize="xl" fontWeight={'700'}>
            {event?.name || "Samantha's Birthday"}
          </Text>
          <Text fontSize="sm" color="brand.900" fontWeight={'700'}>
            {event?.type || 'Private'}
          </Text>
          <Hide below="md">
            <Text fontSize="sm" fontWeight={'500'}>
              {event?.dateAndTime || '12/12/2021 12:00'}
            </Text>
          </Hide>
          <HStack spacing={0}>
            <HStack spacing={1}>
              MdMailOutline
              <Text fontSize="xs" fontWeight={'400'}>
                {event?.numberOfGuests || '100'} Guests
              </Text>
            </HStack>
            <HStack spacing={1}>
              <Icon as={MdLocationPin} />
              <Text fontSize="xs" fontWeight={'400'}>
                {event?.location || 'Manhattan, NY'}
              </Text>
            </HStack>
          </HStack>
        </VStack>
        <VStack flex={1} gap={{ base: 0, md: 5 }} w="100%">
          <HStack w="100%" gap={6} overflow="scroll" py={{ base: 2, md: 1 }}>
            {Object.keys(NavigationTabs).map((key: any) => {
              const tab = NavigationTabs[key as keyof typeof NavigationTabs];
              return (
                <VStack
                  flex={1}
                  minW={{ base: '150px', md: undefined }}
                  borderRadius={'2xl'}
                  bg={currentTab === key ? 'brand.900' : 'transparent'}
                  cursor={'pointer'}
                  onClick={() => dispatch(setCurrentEventTab(key))}
                  //   shadow={'lg'}
                  boxShadow={currentTab !== key ? 'md' : 'none'}
                  //   boxShadow="0px 4px 4px 5px rgba(0, 0, 0, 0.15)"
                  key={key}
                  p={'11px'}
                  transition={'all 0.3s'}
                  _hover={{ bg: 'brad.900' }}
                  _focus={{ bg: 'brand.900' }}
                  _active={{ bg: 'brand.800' }}
                  spacing={1}
                  align="center">
                  {
                    <Image
                      filter={
                        currentTab === key ? 'brightness(0) invert(1)' : 'none'
                      }
                      color={currentTab === key ? 'white' : 'gray.700'}
                      src={tab.icon}
                    />
                  }
                  <Text
                    fontSize={'sm'}
                    color={currentTab === key ? 'white' : 'gray.700'}
                    textTransform="capitalize"
                    noOfLines={1}>
                    {/* {key} */}
                    {t(`event.top_bar_tabs.${key}`)}
                  </Text>
                </VStack>
              );
            })}
          </HStack>
          <HStack
            w="100%"
            justifyContent={'space-between'}
            spacing={{ base: 0.5, md: 2 }}>
            {NavigationTabs[
              currentTab as keyof typeof NavigationTabs
            ]?.tabs.map((tab: any, index) => (
              <VStack
                p={3}
                key={tab.name}
                borderRadius={{ base: 'lg', md: '2xl' }}
                bg="white"
                boxShadow={index % 2 === 0 ? 'lg' : 'none'}
                cursor={'pointer'}
                _hover={{ bg: 'gray.300' }}
                _active={{ bg: 'gray.500' }}
                transition={'all 0.3s'}
                flex={1}
                h="100%"
                justify="center"
                fontSize={{ base: 'xs', md: 'sm' }}
                spacing={1}>
                {index % 2 !== 0 && (
                  <>
                    <Image src={tab.icon} />
                    <Text key={tab.name} noOfLines={1} wordBreak="break-all">
                      {t(`event.top_bar_tabs.sub_tabs.${tab.name}`)}
                    </Text>
                  </>
                )}
                {index % 2 === 0 && (
                  <VStack spacing={1}>
                    <IconButton
                      size="sm"
                      aria-label="add-item"
                      variant="actionIconBlue"
                      as={FiPlus}
                    />
                    <Text
                      opacity={0.2}
                      key={tab.name}
                      noOfLines={1}
                      fontSize={{ base: 'xs', md: 'sm' }}
                      wordBreak="break-all">
                      {t(`event.top_bar_tabs.sub_tabs.${tab.name}`)}
                    </Text>
                  </VStack>
                )}
              </VStack>
            ))}
          </HStack>
        </VStack>
      </HStack>
    </Card>
  );
};
