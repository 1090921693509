import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';
import InviteIcon from 'assets/svg/notification/invite.svg';
import React from 'react';
import { IoLocationOutline } from 'react-icons/io5';
import { Invite } from 'services/@types';

interface InviteCardProps {
  invite: Invite;
  onAccept: () => void;
  onDecline: () => void;
}

const InviteItem: React.FC<InviteCardProps> = ({
  invite,
  onAccept,
  onDecline,
}) => {
  return (
    <Box
      p={4}
      // bg="white"
      border="1px solid #F9FAFB"
      // sx={{ backgroundColor: '#93C3B31A' }}
      borderRadius="12px">
      <VStack align="stretch" spacing={4}>
        <HStack gap="12px">
          <Image w="23px" h="13px" src={InviteIcon} />
          <Text variant="cardSubTitle" color="#1B2037">
            New meeting invite from{' '}
            <span style={{ fontWeight: 700 }}>Emily</span> on
          </Text>
        </HStack>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center">
          <Text>12:00 - 13:00 Team meeting</Text>
          <Flex alignItems="center" gap="2.75px">
            <Icon as={IoLocationOutline} color="general.100" />
            <Text variant="cardSectionTitle">riding 36, tel-aviv</Text>
          </Flex>
        </Flex>
        <HStack justify="space-between">
          <Button variant="h1cta" onClick={onAccept} flex={1}>
            Accept
          </Button>
          <Button variant="h2outlined" onClick={onDecline} flex={1}>
            Decline
          </Button>
        </HStack>
        <Text fontSize="md" color="gray.500" alignSelf="flex-end">
          15 Apr
        </Text>
      </VStack>
    </Box>
  );
};

export default InviteItem;
