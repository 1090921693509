import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';
import KeepHistory from './keepHistory';
import SettingItem from './settingItem';
export default function NotificationSettings() {
  return (
    <Flex w="100%" pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card minH="calc(100vh - 170px)" h="auto" w="100%" p="36px" gap={6}>
        <Flex wrap="wrap" gap="60px">
          {['new_event_notifications', 'task_notifications'].map((title) => (
            <SettingItem key={title} title={title} />
          ))}
        </Flex>
        <Flex wrap="wrap" gap="60px">
          {['new_meeting_notifications', 'system_notifications'].map(
            (title) => (
              <SettingItem key={title} title={title} />
            ),
          )}
        </Flex>
        <Flex wrap="wrap" gap="60px">
          <SettingItem title={'quote_request_notifications'} />
          <KeepHistory />
        </Flex>
      </Card>
    </Flex>
  );
}
