// Chakra imports
import {
  Flex,
  HStack,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import AuthImage from 'components/authImage/AuthImage';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Thread } from 'services/@types';
import UserService from 'services/user.api';
import FavoriteIcon from '../../../../../assets/img/favorite/favorite.png';
export default function ThreadListItem(props: {
  thread: Thread;
  onSendInvite?: () => void;
  [x: string]: any;
}) {
  const { thread, onSendInvite, ...rest } = props;
  const user = useMyUser();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  const secondaryTextColor = useColorModeValue('primary.100', 'whiteAlpha.600');

  const { i18n } = useTranslation();
  const memoIsFavorite = false;
  // const memoNewMessages = useMemo(
  //   () => thread?.lastMessage,
  //   [thread?.newMessages],
  // );
  const memoizedTimestamp = useMemo(() => {
    // check if today
    if (moment().isSame(thread?.lastMessage?.createdAt, 'day')) {
      return moment(thread?.lastMessage?.createdAt).format('LT');
    } else {
      return moment(thread?.lastMessage?.createdAt).format('DD MMM');
    }
  }, [thread?.lastMessage?.createdAt]);

  const lastMessage = useMemo(() => {
    return thread?.lastMessage?.text;
  }, [thread?.lastMessage?.text]);

  const participant = useMemo(
    () => (thread?.participants as string[])?.find((p) => p !== user?.id) || '',
    [thread?.participants, user?.id],
  );

  const participantResult = useQuery({
    queryKey: [`getParticipant-${participant}`],
    queryFn: () => UserService.getUser(participant),
    enabled: !!participant,
  });

  const avatarPath = useMemo(() => {
    return (
      participantResult.data?.media?.find((media) => media?.type === 'avatar')
        ?.url || ''
    );
  }, [participantResult?.data]);

  const name = useMemo(() => {
    return participantResult?.data?.firstName &&
      participantResult?.data?.lastName
      ? participantResult?.data?.firstName +
          ' ' +
          participantResult?.data?.lastName
      : '';
  }, [participantResult?.data]);

  return (
    <Flex
      w="100%"
      py={4}
      ps={2}
      pe={'18px'}
      transition="all 0.2s"
      gap={7}
      flex={1}
      borderRadius="8px"
      bg="#F5F9FF"
      position="relative"
      width="calc(100% - 34px)"
      cursor="pointer"
      {...rest}
      _hover={{ bg: '#93C3B31A' }}
      align="center">
      <Flex pos="relative" alignSelf={'start'}>
        <Image
          alt=""
          src={FavoriteIcon}
          pos="absolute"
          w="25px"
          h="25px"
          zIndex="20"
          visibility={memoIsFavorite ? 'visible' : 'hidden'}
        />
        <AuthImage
          name={name}
          isAvatar
          path={avatarPath}
          h={{ base: '60px', sm: '52px', md: '60px' }}
          w={{ base: '60px', sm: '52px', md: '60px' }}
          borderRadius="full"
        />
        <Flex
          w={'14px'}
          h={'14px'}
          bottom={0}
          right={0}
          bg="brand.600"
          borderRadius="full"
          border="2px solid #fff"
          position="absolute"
        />
      </Flex>

      <Flex direction="column" flex={1} position="relative">
        <VStack align="start" spacing={0} flex={1} wordBreak={'break-all'}>
          <HStack w="100%">
            <Text
              color={textColor}
              fontSize="xl"
              flex={1}
              noOfLines={1}
              fontWeight="700">
              {name}
            </Text>
            {/* {memoNewMessages && memoNewMessages > 0 && (
              <Text
                minW={6}
                h={6}
                px={1}
                textAlign="center"
                borderRadius={12}
                color="#fff"
                bg="#5FBF96">
                {memoNewMessages}
              </Text>
            )} */}
          </HStack>
          <Text
            noOfLines={1}
            flex={1}
            maxW={200}
            fontSize={{ base: 'sm', md: 'lg' }}
            wordBreak={'break-all'}>
            {/* // display={category?.length > 0 ? 'flex' : 'none'}> */}
            {/* some gold text */}
            {lastMessage}
          </Text>
        </VStack>
      </Flex>
      <Text
        color={secondaryTextColor}
        fontSize={{ base: 'xs', md: 'sm' }}
        fontWeight="500"
        bottom={4}
        right={i18n.dir() === 'rtl' ? 'auto' : 2}
        left={i18n.dir() === 'rtl' ? 2 : 'auto'}
        position={'absolute'}
        noOfLines={1}>
        {memoizedTimestamp}
      </Text>
    </Flex>
  );
}
