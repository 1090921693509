import {
  Button,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiMoreHorizontal } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';

export default function TrackItemNote() {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);
  return (
    <Flex
      gap="12px"
      position="relative"
      onMouseEnter={() => setIsBtnVisible(true)}
      onMouseLeave={() => setIsBtnVisible(false)}>
      <AuthImage
        path=""
        name="jack"
        isAvatar
        w="24px"
        h="24px"
        minW="24px"
        opacity={isOpen ? 0.3 : 1}
      />
      <Flex flexDirection="column" gap="10px" opacity={isOpen ? 0.3 : 1}>
        <Flex gap="2px">
          <Text variant="trackItemNoteTitle">Ana Tsegra</Text>
          <Text variant="trackItemNoteTitleDate">07 Jul 2025</Text>
        </Flex>

        <Text display="inline" variant="trackItemNoteTitle">
          <Text
            variant="trackItemNoteTitle"
            color="#7BA395"
            display="inline"
            w="fit-content">
            Danny Cohen
          </Text>{' '}
          <Text
            variant="trackItemNoteTitleDate"
            display="inline"
            w="fit-content">
            Danny, do you remember to buy a new lens?
          </Text>
        </Text>
        <Text variant="trackItemNoteTitle">2 images</Text>
      </Flex>
      <Menu placement="right" variant="threeDots" isOpen={isOpen} closeOnBlur>
        <MenuButton
          as={IconButton}
          variant="actionIconTransparent"
          icon={<Icon as={FiMoreHorizontal} w="20px" h="20px" />}
          onClick={onToggle}
          transition="all 0.4s linear"
          visibility={{
            base: 'visible',
            md: isBtnVisible ? 'visible' : 'hidden',
          }}
        />
        <MenuList>
          <Button
            as={IconButton}
            variant="actionIconRed"
            onClick={onClose}
            icon={<Icon as={FaRegTrashAlt} w="20px" h="20px" />}
          />
          <Button
            as={IconButton}
            aria-label="edit"
            onClick={onClose}
            variant="actionIconGrey"
            icon={<Icon as={MdEdit} w="20px" h="20px" />}
          />
        </MenuList>
      </Menu>
    </Flex>
  );
}
