import { Flex, HStack, Image, Text } from '@chakra-ui/react';
import BrilliantIcon from 'assets/svg/notification/brilliant.svg';

export default function LinkNotification() {
  return (
    <Flex
      border="1px solid #F9FAFB"
      borderRadius="12px"
      p="16px 8px"
      flexDirection="column">
      <HStack gap="12px">
        <Image src={BrilliantIcon} alt="" w="25px" h="auto" />
        <Text variant="cardSubTitle" color="#1B2037">
          We just added a new feature!{' '}
          <Text variant="eventLink" color="#AA915D">
            come check it out
          </Text>
        </Text>
      </HStack>
      <Text fontSize="md" color="gray.500" alignSelf="flex-end">
        2 weeks ago
      </Text>
    </Flex>
  );
}
